<template>
  <fragment>
    <data-table
      :data="searchResults.results"
      :columns="columns"
      :total="searchResults.total"
      :query="searchCriteria"
      :selectedRows="selectedRecords"
      :isLoading="isDataLoading"
      :column-filter="$permissions().hasProjectSearchColumns"
      @selected-columns-changed="onSelectedColumnsChanged"
      @column-sort-changed="onColumnSortChanged"
      @page-size-changed="onPageSizeChanged"
      :onRowDblClick="goToProject"
      :rowClass="setContextualClass"
    >
      <template v-slot:header-custom-btns>
        <button type="button" class="btn btn-outline-dark" @click="goToMapView" :disabled="searchResults.total === 0">View Map</button>
        <button type="button" class="btn btn-outline-dark ld-ext-right" :class="{ 'running' : isExporting }" @click="exportDataset(false)"
          :disabled="isExporting || searchResults.results.length === 0" v-if="$permissions().hasProjectExcelExport"
        >
          Export to Excel
          <div class="ld ld-ring ld-spin"></div>
        </button>
        <button type="button" class="btn btn-outline-dark ld-ext-right" :class="{ 'running' : isExporting }" @click="exportDataset(true)"
          :disabled="isExporting || searchResults.results.length === 0" v-if="$permissions().hasProjectExcelExport"
        >
          Export All to Excel
          <div class="ld ld-ring ld-spin"></div>
        </button>
        <button type="button" class="btn btn-outline-dark" @click="print">Print</button>
        <!-- <button type="button" class="btn btn-outline-dark" @click="openBulletinReportModal" v-if="$permissions().hasProjectBulletinReportExport">Bulletin Report</button> -->
      </template>
      <template v-slot:ocaaNumber="{ row }">{{ row.ocaaNumber }}</template>
      <template v-slot:lcaNumber="{ row }">{{ row.lcaNumber }}</template>
      <template v-slot:title="{ row }">{{ row.title }}</template>
      <template v-slot:classificationOfWork="{ row }">{{ row.classificationOfWork }}</template>
      <template v-if="showDocumentSearch" v-slot:keywordDensity="{ row }"><span v-html="row.keywordDensity">{{ row.keywordDensity }}</span></template>
      <template v-slot:closingDate="{ row }">{{ row.closingDate | datetime('MMM dd, yyyy h:mm a') }} {{ row.timeZone.code }}</template>
      <template v-slot:owner.owner.name="{ row }">{{ row.owner || '---' }}</template>
      <template v-slot:ownerType.name="{ row }">{{ row.ownerType || '---' }}</template>
      <template v-slot:addenda="{ row }">{{ row.addenda }}</template>
      <template v-slot:siteMeeting="{ row }">{{ row.siteMeetings.length ? row.siteMeetings[0].text : '---' }}</template>
      <template v-slot:belongZone.name="{ row }">{{ row.belongZone }}</template>
      <template v-slot:city="{ row }">{{ getCitiesDisplay(row.addresses) }}</template>
      <template v-slot:tenderStage.name="{ row }">{{ row.tenderStage }}</template>
      <template v-slot:procurementType="{ row }">{{ row.procurementType }}</template>
      <template v-slot:funding.name="{ row }">{{ row.funding }}</template>
      <template v-slot:ownerSolicitationNumber="{ row }"> {{ row.ownerSolicitationNumber }} </template>
      <template v-slot:bids.company.name="{ row }">
        <span v-for="(bidder, index) in filteredRowBids(row.bids)" :key="'search-result-bidder-' + index">
          {{ bidder.companyName }} <br />
        </span>
        <span v-if="row.bids.length === 0">---</span>
      </template>
      <template v-slot:contact.companyName="{ row }">
        <span v-for="(contact, index) in row.contacts" :key="'search-result-contact-' + index">
          {{ contact.companyName }} <br />
        </span>
        <span v-if="row.contacts.length === 0">---</span>
      </template>
      <template v-slot:addendaInfo="{ row }">
        <span style="white-space: pre;">{{ row.addendaInfo || '---' }}</span>
      </template>
      <template v-slot:createdOn="{ row }">{{ row.createdOn | datetime('MMM dd, yyyy h:mm a') }} {{ row.timeZone.code }}</template>
      <template v-slot:updatedOn="{ row }">{{ row.updatedOn | datetime('MMM dd, yyyy h:mm a') }} {{ row.updatedOn != null && row.updatedOn != undefined ? row.timeZone.code:"" }}</template>
      <template v-slot="{ row }">
        <td style="width: 125px;">
          <follow-button v-if="row.allowView" class="btn btn-link btn-sm" :projectId="row.id" :projectTitle="row.title" :value="row.isUserFollowing">
            <template v-slot:is-following>
              <i class="far fa-check-circle"></i>
              <span class="sr-only">Unfollow</span>
            </template>
            <template v-slot:is-not-following>
              <i class="fas fa-rss"></i>
              <span class="sr-only">Follow</span>
            </template>
          </follow-button>

          <router-link :to="{ name: 'project-info', params: { id: row.id, qs: $route.query } }" class="btn btn-link btn-sm" role="button" :aria-label="'View ' + row.title">
            <i class="far fa-eye"></i>
          </router-link>

          <router-link v-if="row.allowEdit" :to="'/project/edit/' + row.id" class="btn btn-link btn-sm" role="button" :aria-label="'Edit ' + row.title">
            <i class="far fa-edit"></i>
          </router-link>
        </td>
      </template>
      <template v-slot:loading>
        <loading :active.sync="isDataLoading" :is-full-page="false"></loading>
      </template>
      <template v-slot:no-records>
        <div v-if="isInitialLoad === true" class="alert alert-info" role="alert">
          Please use the filters on the left and then hit 'Search' or select a saved search from 'My Searches'.
          <hr />
          <button type="submit" class="btn-primary btn btn-block" @click="triggerSearch">Search</button>
        </div>
        <div v-if="isInitialLoad === false" class="alert alert-warning" role="alert">
          No records found.
        </div>
      </template>
    </data-table>
    <b-modal id="bv-modal-bulletin-report" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <project-bullet-report></project-bullet-report>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FileDownload from "@/utils/fileDownload";
import { ProjectService } from "@/services/";
import { DataTable } from "@/components/datatable/";
import Toast from "@/utils/toast";
import ProjectFollowButton from "@/views/project/components/project-follow-button.vue";
import ProjectBulletinReport from "@/views/project/components/project-bulletin-report.vue";
import { alpha as alphaSort } from "@/utils/sort";

const STORAGE_KEY_PROJECT_TABLE_SELECTED_COLUMNS = 'project-table-selected-columns';
const STORAGE_KEY_PROJECT_TABLE_COLUMN_SORTING = 'project-table-column-sorting';
const STORAGE_KEY_PROJECT_TABLE_PAGE_SIZE = 'project-table-page-size';

export default {
  name: "project-search-results",
  props: ["isDataLoading", "projects", "searchCriteria", "searchResults", "documentSearch"],
  components: { DataTable, "follow-button": ProjectFollowButton, "project-bullet-report": ProjectBulletinReport },
  data: function() {
    return {
      isExporting: false,
      isInitialLoad: true,
      selectedRecords: [],
      columns: [
        { display: "Project Number", field: "ocaaNumber", exportFieldName: "ocaaNumber", sortable: !this.documentSearch },
        { display: "LCA Number", field: "lcaNumber", exportFieldName: "lcaNumber", sortable: !this.documentSearch,isVisible:false },
        { display: "Project Title", field: "title", exportFieldName: "title", sortable: !this.documentSearch },
        { display: "Classification of Work", field: "classificationOfWork", exportFieldName: "classificationOfWork", sortable: !this.documentSearch },
        { display: "Closing Date", field: "closingDate", exportFieldName: "closingDate", sortable: !this.documentSearch },
        { display: "Owner", field: "owner.owner.name", exportFieldName: "owner", sortable: !this.documentSearch },
        { display: "Owner Type", field: "ownerType.name", exportFieldName: "ownerType", sortable: !this.documentSearch },
        { display: "Addenda", field: "addenda", exportFieldName: "addenda" },
        { display: "Site Meeting", field: "siteMeeting", exportFieldName: "siteMeetings", isVisible: false },
        { display: "Project Location Zone", field: "belongZone.name", exportFieldName: "zone", sortable: !this.documentSearch, isVisible: false },
        { display: "City", field: "city", exportFieldName: "city", sortable: !this.documentSearch },
        { display: "Tender Stage", field: "tenderStage.name", exportFieldName: "tenderStage", sortable: !this.documentSearch },
        { display: "Procurement Type", field: "procurementType", exportFieldName: "procurementType", sortable: !this.documentSearch },
        { display: "Funding", field: "funding.name", exportFieldName: "funding", sortable: !this.documentSearch, isVisible: false },
        { display: "Budget", field: "budget", exportFieldName: "budget", sortable: !this.documentSearch, isVisible: false },
        { display: "Owner Number", field: "ownerSolicitationNumber", exportFieldName: "ownerSolicitationNumber", sortable: !this.documentSearch, isVisible: false },
        { display: "Bidders", field: "bids.company.name", exportFieldName: "bidders", isVisible: false },
        { display: "Project Contact(s)", field: "contact.companyName", exportFieldName: "projectContacts", isVisible: false },
        { display: "Addenda Notes", field: "addendaInfo", exportFieldName: "addendaInfo", isVisible: false },
        { display: "Created Date", field: "createdOn", exportFieldName: "createdOn", isVisible: false },
        { display: "Last Updated Date", field: "updatedOn", exportFieldName: "updatedOn", isVisible: false },
        { ignore: true }
      ],
      query: {}
    };
  },
  computed: {
    visibleColumnNames() {
      return this.columns
        .filter(column => !column.ignore && column.isVisible)
        .map(column => column.exportFieldName);
    },
    hasEditProjectPermission() {
      return this.$permissions().hasEditProjectPermission;
    },
    showDocumentSearch() {
        return (this.getUseDocumentSearch || '').toUpperCase() === 'TRUE'
    },
    ...mapGetters([
      "getProjects",
      "getFundingTypes",
      "getTenderStages",
      "getZones",
      "getUseDocumentSearch"
    ])
  },
  methods: {
    ...mapActions([
      "fetchClientSettings"
    ]),
    exportDataset(exportAll) {
      let params = { ...this.searchCriteria };

      this.isExporting = true;

      if (params['createdStart'] || params['createdEnd']) {
        params.created = {};

        if (params['createdStart']) {
          params.created.start = params['createdStart'];
          delete params['createdStart'];
        }
        if (params['createdEnd']) {
          params.created.end = params['createdEnd'];
          delete params['createdEnd'];
        }
      }

      if (params['closingStart'] || params['closingEnd']) {
        params.closing = {};

        if (params['closingStart']) {
          params.closing.start = params['closingStart'];
          delete params['closingStart'];
        }
        if (params['closingEnd']) {
          params.closing.end = params['closingEnd'];
          delete params['closingEnd'];
        }
      }

      if (params['lastUpdatedStart'] || params['lastUpdatedEnd']) {
        params.lastUpdated = {};

        if (params['lastUpdatedStart']) {
          params.lastUpdated.start = params['lastUpdatedStart'];
          delete params['lastUpdatedStart'];
        }
        if (params['lastUpdatedEnd']) {
          params.lastUpdated.end = params['lastUpdatedEnd'];
          delete params['lastUpdatedEnd'];
        }
      }

      if (params['siteMeetingStart'] || params['siteMeetingEnd']) {
        params.siteMeeting = {};

        if (params['siteMeetingStart']) {
          params.siteMeeting.start = params['siteMeetingStart'];
          delete params['siteMeetingStart'];
        }
        if (params['siteMeetingEnd']) {
          params.siteMeeting.end = params['siteMeetingEnd'];
          delete params['siteMeetingEnd'];
        }
      }

      if (!exportAll) {
        let exportParams = {
          search: params,
          columns: this.visibleColumnNames
        };

        return ProjectService.export(exportParams)
          .then(response => FileDownload("ProjectExport.xlsx", response.data))
          .finally(() => this.isExporting = false);
      } else {
        let exportParams = {
          search: params,
          columms: this.columns.filter(column => !column.ignore).map(column => column.exportFieldName)
        };

        return ProjectService.export(exportParams)
          .then(response => FileDownload("ProjectExport-AllFields.xlsx", response.data))
          .finally(() => this.isExporting = false);
      }
    },
    filteredRowBids(bids) {
      return bids.filter((s1, pos, arr) => arr.findIndex((s2) => s2.companyName === s1.companyName) === pos);
    },
    getCitiesDisplay(addresses) {
      if (!addresses || addresses.length === 0) return "---";

      return addresses.map(a => a.city).sort((a, b) => alphaSort(a, b)).join(", ");
    },
    getFundingTypeName(id) {
      let selectedFundingType = this.getFundingTypes.find(
        type => type.id === id
      );
      return (selectedFundingType && selectedFundingType.name) || "";
    },
    getSiteMeetingDisplay(siteMeeting) {
      return (siteMeeting && siteMeeting.text) || "---";
    },
    getTenderStageName(id) {
      let selectedTenderStage = this.getTenderStages.find(
        stage => stage.id === id
      );
      return (selectedTenderStage && selectedTenderStage.name) || "";
    },
    getZoneName(id) {
      let selectedZone = this.getZones.find(zone => zone.id == id);
      return (selectedZone && selectedZone.name) || "";
    },
    goToMapView() {
      this.$emit("change-tab", "MAPS");
    },
    goToProject(item) {
      this.$router.push({ name: 'project-info', params: { id: item.id, qs: this.$route.query } });
    },
    openBulletinReportModal () {
      this.$bvModal.show("bv-modal-bulletin-report");
    },
    onPageSizeChanged (pageSize) {
      window.localStorage.setItem(STORAGE_KEY_PROJECT_TABLE_PAGE_SIZE, JSON.stringify(pageSize));
    },
    onSelectedColumnsChanged(columns) {
      let activeColumns = columns.map(column => ({ field: column.field, isVisible: column.isVisible }));
      window.localStorage.setItem(STORAGE_KEY_PROJECT_TABLE_SELECTED_COLUMNS, JSON.stringify(activeColumns));
    },
    onColumnSortChanged(sort) {
      window.localStorage.setItem(STORAGE_KEY_PROJECT_TABLE_COLUMN_SORTING, JSON.stringify(sort));
    },
    print () {
      window.print();
    },
    setContextualClass(row) {
      if (!row.showProject) return 'table-warning';
      if (row.isPrivate) return 'table-info';
      return null;
    },
    subscribeToProject(projectId, isUserFollowing) {
      if (isUserFollowing) return;

      ProjectService.subscribe(projectId)
        .then(() => Toast.success(this, "Successfully following project!"))
        .catch(() => Toast.danger(this, "Oops! An error has occured"));
    },
    triggerSearch () {
      this.$emit('trigger-search');
    }
  },
  async mounted () {
    this.isInitialLoad = Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object;

    this.fetchClientSettings().then(() => {
      if (!this.showDocumentSearch){
        this.columns.map(e => {
          if (e.title === "keywordDensity") {
            this.columns.splice(2,1);
          }
        })
      }
      else {
        this.columns.splice(2, 0, { display: "Keyword Density", field: "keywordDensity", exportFieldName: "keywordDensity", isVisible: true})
      }
    });

    let savedColumns = JSON.parse(window.localStorage.getItem(STORAGE_KEY_PROJECT_TABLE_SELECTED_COLUMNS)) || [];
    this.columns.forEach(column => Object.assign(column, savedColumns.find(i => i.field === column.field)));
  }
};
</script>
